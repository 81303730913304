import { Col, Descriptions, Row, Tag, Typography } from "antd";
import CustomDescriptions from "components/CustomDescriptions";
import FilterDate from "components/CustomFilterMenu/FilterDate";
import CustomTable from "components/CustomTable";
import { convertPaymentMethod } from "features/Agent/constants";
import CustomDivider from "features/KeyManagement/KeyDetail/CustomDivider";
import { t } from "i18next";
import { formatCurrency } from "utils/helperFuncs";

export const PaymentInfoTab = ({ dataDetail }) => {
  const columns = [
    {
      title: <span className="title-column">STT</span>,
      align: "center",
      width: 50,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: <span className="title-column">{t("seller.activitylogtabs.table.dateUpdate")}</span>,
      align: "center",
      width: 120,
      dataIndex: "updatedAt",
      render: (_, record, index) => {
        return "01/01/2022 20:09:20";
      },
      sorter: true,
    },
    {
      title: <span className="title-column">HÀNH ĐỘNG</span>,
      align: "center",
      dataIndex: "action",
      render: (_, record, index) => {
        return "Hết hạn sử dụng xu";
      },
    },
    {
      title: <span className="title-column">CHI TIẾT/ LÝ DO</span>,
      align: "center",
      render: (_, record, index) => {
        return "Hết hạn sử dụng xu vào 31/12/2023";
      },
    },
    {
      title: <span className="title-column">GIAO DỊCH XU</span>,
      align: "center",
      dataIndex: "updatedBy",
      render: (_, record, index) => {
        return <Tag color="green">+ 600 xu</Tag>;
      },
    },
  ];
  return (
    <Col span={24} style={{ padding: "0 12px" }}>
      <Row gutter={[24, 16]}>
        <Col span={10}>
          <CustomDescriptions
            title={<CustomDivider title={t("warehouse.create.Rules")} />}
            content={
              <>
                <Descriptions.Item label={t("order.orderDetail.paymentMethod")}>
                  {dataDetail?.paymentMethod ? (
                    <Tag color="geekblue">{convertPaymentMethod(dataDetail?.paymentMethod)}</Tag>
                  ) : (
                    "- -"
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.debt")}>
                  <Typography.Text>{formatCurrency(dataDetail?.debtLimit) || "--"}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.outstanding_balance")}>
                  <Typography.Text>
                    {formatCurrency(dataDetail?.debtLimit - dataDetail?.availabilityLimit) || "--"}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.debtLimit")}>
                  <Typography.Text>{formatCurrency(dataDetail?.availabilityLimit) || "--"}</Typography.Text>
                </Descriptions.Item>
              </>
            }
          />
        </Col>
        <Col span={7}>
          <CustomDescriptions
            title={<CustomDivider title={t("cart.account_balance")} />}
            content={
              <>
                <Descriptions.Item label={"Hỗ trợ bán hàng"}>
                  <Typography.Text>Có thể bán thẳng cho khách hàng cuối </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.account_balance")}>
                  <Typography.Text>{formatCurrency(dataDetail?.payableTotalBalance)}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.personal_income_tax")}>
                  <Typography.Text>{formatCurrency(dataDetail?.personalIncomeTax)}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={t("cart.availableBalances")}>
                  <Typography.Link strong>{formatCurrency(dataDetail?.payableAvailabilityBalance)}</Typography.Link>
                </Descriptions.Item>
              </>
            }
          />
        </Col>
        <Col span={7}>
          <CustomDescriptions
            title={<CustomDivider title={"Số dư xu"} />}
            content={
              <>
                <Descriptions.Item label={"Số lượng xu khả dụng "}>
                  <Typography.Text>2.500 xu</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={"Hạn sử dụng"}>
                  <Typography.Text>300 xu hết hạn vào 12/05/2025</Typography.Text>
                </Descriptions.Item>
              </>
            }
          />
        </Col>
      </Row>
      <Row gutter={[24, 16]} style={{ marginTop: "24px" }}>
        <Col span={24}>
          <div style={{ width: "150px" }}>
            <FilterDate title={"Thời gian"} />
          </div>
        </Col>
        <Col span={24}>
          <CustomTable columns={columns} dataSource={[{}, {}, {}]} loading={false} />
        </Col>
      </Row>
    </Col>
  );
};
